<template>
  <div class="heading-elements">
    <ul class="list-inline mb-0">
      <li v-if="availableActions.includes('collapse')">
        <b-button
            data-action="collapse"
            :class="{ rotate: isCollapsed }"
            @click="$emit('collapse')"
            class="btn btn-sm btn-danger"
        >
          <feather-icon
              icon="ChevronDownIcon"
              size="16"
          />
        </b-button>
      </li>
      <li v-if="availableActions.includes('create')">
        <b-button
            data-action="reload"
            @click="$emit('create')"
            v-b-tooltip.hover.top="$t('Create item')"
            class="btn-icon"
            variant="success"
        >
          <feather-icon
              icon="PlusIcon"
              size="14"
          />
        </b-button>
      </li>
      <li v-if="availableActions.includes('back')">
        <b-button
            data-action="back"
            @click="$emit('back')"
            v-b-tooltip.hover.top="$t('Go back')"
            class="btn-icon"
        >
          <feather-icon
              icon="ArrowLeftCircleIcon"
              size="14"
          />
        </b-button>
      </li>
      <li v-if="availableActions.includes('refresh')">
        <b-button
            data-action="reload"
            @click="$emit('refresh')"
            v-b-tooltip.hover.top="$t('Reload')"
            class="btn-icon"
            :disabled="isRefreshing"
        >
          <feather-icon v-if="!isRefreshing"
                        icon="RotateCwIcon"
                        size="14"
          />
          <b-spinner v-else small />
        </b-button>
      </li>
      <li v-if="availableActions.includes('save')">
        <b-button
            class="btn-icon"
            data-action="save"
            @click="$emit('save')"
            v-b-tooltip.hover.top="$t('Save')"
            variant="success"
            :disabled="isSaving"
        >
          <feather-icon
              v-if="!isSaving"
              icon="SaveIcon"
              size="14"
          />
          <b-spinner v-else small />
        </b-button>
      </li>
      <li v-if="availableActions.includes('delete')" v-b-tooltip.hover.top="$t('Delete')">
        <b-button
            data-action="reload"
            @click="$emit('delete')"
            class="btn-icon"
            variant="danger"
            :disabled="isDeleting"
        >
          <feather-icon
              icon="Trash2Icon"
              size="14"
              v-if="!isDeleting"
          />
          <b-spinner v-else small />
        </b-button>
      </li>
      <li v-if="availableActions.includes('close')">
        <b-button
            data-action="close"
            @click="$emit('close')"
            class="btn-icon"
        >
          <feather-icon
              icon="XIcon"
              size="14"
          />
        </b-button>
      </li>
    </ul>
  </div>
</template>

<script>
import {VBTooltip, BButton, BSpinner} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BSpinner
  },
  props: {
    isCollapsed: {
      type: Boolean,
      requried: true,
    },
    availableActions: {
      type: Array,
      default: () => [],
    },
    loaded: {
      type: Boolean,
      default: true,
    },
    isSaving: {
      type: Boolean,
      default: true,
    },
    isRefreshing: {
      type: Boolean,
      default: true,
    },
    isDeleting: {
      type: Boolean,
      default: true,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
}
</script>
