<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('invoice-adress-list')"
        @save="createItem"
        :title="currentItem.title"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>


    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-row>
        <b-col cols="12">
          <b-card>

            <b-form @submit.stop.prevent="handleSubmit(addAddress)" @reset.prevent="resetForm">

              <b-row>
                <b-col cols="6">
                  <b-form-group :label="$t('Title for internal use')">
                    <validation-provider #default="{errors}" :name="$t('Title')" rules="required">
                      <b-form-input v-model="currentItem.title" :placeholder="$t('Title for internal use')" />

                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="Object.keys(validation).includes('title')" class="text-danger">{{
                        $t(validation.title[0])
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group :label="$t('Firm')">
                    <validation-provider #default="{errors}" :name="$t('Firm')" rules="required">
                      <b-form-input v-model="currentItem.firm" :placeholder="$t('Firm')" />

                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="Object.keys(validation).includes('firm')" class="text-danger">{{
                        $t(validation.firm[0])
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group :label="$t('Firstname')">
                    <validation-provider #default="{errors}" :name="$t('Firstname')" rules="required">
                      <b-form-input v-model="currentItem.firstname" :placeholder="$t('Firstname')" />

                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="Object.keys(validation).includes('firstname')" class="text-danger">{{
                        $t(validation.firstname[0])
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group :label="$t('Name')">
                    <validation-provider #default="{errors}" :name="$t('Name')" rules="required">
                      <b-form-input v-model="currentItem.name" :placeholder="$t('Name')" />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="Object.keys(validation).includes('name')" class="text-danger">{{
                        $t(validation.name[0])
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group :label="$t('Street')">
                    <validation-provider #default="{errors}" :name="$t('Street')" rules="required">
                      <b-form-input v-model="currentItem.street" :placeholder="$t('Street')" />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="Object.keys(validation).includes('street')" class="text-danger">{{
                        $t(validation.street[0])
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group :label="$t('Addressline')">
                    <b-form-input v-model="currentItem.addressline_2" :placeholder="$t('Addressline')" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group :label="$t('City')">
                    <validation-provider #default="{errors}" :name="$t('City')" rules="required">
                      <b-form-input v-model="currentItem.city" :placeholder="$t('City')" />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="Object.keys(validation).includes('city')" class="text-danger">{{
                        $t(validation.city[0])
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group :label="$t('Postal')">
                    <validation-provider #default="{errors}" :name="$t('Postal')" rules="required">
                      <b-form-input v-model="currentItem.postal" :placeholder="$t('Postal')" type="number" />

                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="Object.keys(validation).includes('postal')" class="text-danger">{{
                        $t(validation.postal[0])
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>

              <b-row>

                <b-col cols="6">
                  <b-form-group :label="$t('Country')">
                    <validation-provider #default="{errors}" :name="$t('Country')" rules="required">
                      <b-form-input v-model="currentItem.country" :placeholder="$t('Country')" />

                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="Object.keys(validation).includes('country')" class="text-danger">{{
                        $t(validation.country[0])
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="d-flex mt-1">
                <b-button v-if="!isPrevRouteCart" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success"
                  type="submit" @click.prevent="createItem">
                  {{ $t('Add Invoice Adress') }}
                </b-button>
                <b-button v-if="isPrevRouteCart" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success"
                  type="submit" @click.prevent="createItem">
                  {{ $t('Add Invoice Adress') }}
                </b-button>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
  </component>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BFormTimepicker,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BSidebar,
  BToast,
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {alphaNum, email, required} from '@validations'
import Ripple from 'vue-ripple-directive'
import {mapActions} from "vuex";
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadioGroup,
    BFormTimepicker,
    BFormTextarea,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    required,
    alphaNum,
    email,
    BFormCheckbox,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      isPrevRouteCart: false,
      nextRoute: 'invoice-adress-list',
      currentItem: {
        firm: '',
        title: '',
        firstname: '',
        name: '',
        street: '',
        addressline_2: '',
        postal: '',
        city: '',
        country: '',

      },
      loaded: true,
      validation: {},
      validate: false,

    }
  },

  methods: {
    ...mapActions(['ADD_INVOICE_ADDRESSES']),
    showSuccessMessage() {
      return setTimeout(() => {
        this.$bvToast.toast(this.$i18n.t('Invoice successfully added'), {
          title: this.$i18n.t('Success'),
          variant: 'success',
          toaster: 'b-toaster-bottom-right'
        })
      })
    },

    createItem() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}

            if (success) {
              this.ADD_INVOICE_ADDRESSES(this.currentItem).then(response => {
                this.$router.push(
                    {
                      name: this.nextRoute,
                    },
                    this.showSuccessMessage()
                )
              }).catch(error => {
                if (error.response.status == 422) {
                  this.validation = error.response.data.errors;
                  this.validate = true
                } else {
                  this.$bvToast.toast(this.$i18n.t(error.response.data.message), {
                    title: this.$i18n.t(error.response.data.message),
                    variant: "danger",
                    toaster: "b-toaster-bottom-right",
                  });
                }

              })
            }
          })
    },

  },
}
</script>

